const stripe = require('stripe')('sk_test_51NvKA7HnOiQnHO5nIaL6pJb09ZnxD83KwaYyLmYAAtDGz4gEveyqKxjIRCBqwC1sisj6U1wtiwy0KqkitUy9iq0G00DrAM4EGj');

//Function to checkout on  stripe
export const createPaymentSession = async (custumerStipeId) => {
    const session = await stripe.checkout.sessions.create({
    payment_method_types: ['card'],
    customer: custumerStipeId,
    line_items: [
        {
            price: 'price_1NvL5oHnOiQnHO5nEhxrVVsd',
            quantity: 1,
        },
        ],
    mode: "subscription",
    success_url: `${window.location.origin}/`,
    cancel_url: `${window.location.origin}/`
    });

    return session;
};




