// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDDZxpsmqnuAXqoCHzxsiXYXn7ju5C3yCI",
  authDomain: "testestripe-f2b6f.firebaseapp.com",
  projectId: "testestripe-f2b6f",
  storageBucket: "testestripe-f2b6f.appspot.com",
  messagingSenderId: "829185703870",
  appId: "1:829185703870:web:eaa2050bcc9843279f83ef"
};

// Initialize Firebase
export const firestoreApp = initializeApp(firebaseConfig);
const auth = getAuth(firestoreApp);
const db = getFirestore(firestoreApp);

export{auth, db}
