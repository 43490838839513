import "@stripe/stripe-js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Checkout from "./Checkout";
import Success from "./Success";
import Cancel from "./cancel";
import SignOut from './SignOut';

export default function App() {
  return (
    <div className="App">
      <SignOut/>
      <Router>
        <Routes>
          <Route index element={<Checkout />} />
          <Route path="/success" element={<Success />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </Router>
      
    </div>
  );
}
