import '../style.css';
import React, { useState, useEffect, useCallback  } from 'react';
import { signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { auth, firestoreApp } from '../firebase';
import { getFirestore, collection, getDoc, doc, getDocs, query } from 'firebase/firestore';
import { createPaymentSession } from '../stripePayment';

function CheckOut() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const [userData, setUserData] = useState(null);
  const [isSubscriber, setIsSubscriber] = useState(false);

  const db = getFirestore(firestoreApp);

  const getUserData = useCallback(async (user) => {
    if (user) {
      try {
        const userRef = doc(db, 'customers', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
          setUserData(userDoc.data());

          const subscriptionsQuery = query(collection(userRef, 'subscriptions'));
          const subscriptionsSnapshot = await getDocs(subscriptionsQuery);
          const subscriptionsData = subscriptionsSnapshot.docs.map(
            (doc) => doc.data()
          );

          // Check if any subscription has the status "active"
          const hasActiveSubscription = subscriptionsData.some(
            (subscription) => subscription.status === 'active'
          );

          setIsSubscriber(hasActiveSubscription);
        } else {
          console.error('User document does not exist.');
        }
      } catch (error) {
        console.error('Error fetching user data from Firestore:', error);
      }
    }
  }, [db]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        getUserData(user);
      } else {
        setUser(null);
        setUserData(null);
        setIsSubscriber(false);
      }
    }, [getUserData]);

    return () => unsubscribe();
  }, [getUserData]);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log(userCredential.user);
      setLoginError(null);
    } catch (error) {
      console.error(error);
      setLoginError('Usuário ou senha incorretos.');
      setUser(null);
    }
  };

  const handlePayment = async () => {
    try {
      const session = await createPaymentSession(userData.stripeId);
      window.location.href = session.url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="App">
      <form onSubmit={handleSignIn}>
        <div className="Panel">
          <img
            src="android-chrome-192x192.png"
            height={90}
            width={90}
            alt="PhyStore Logo"
          />
          {user ? (
            <div>
              <h1>Welcome to PhyStore</h1>
              <p>User Data:</p>
              <pre>{JSON.stringify(userData, null, 2)}</pre>
              {isSubscriber ? (
                <p style={{color: 'green'}}>You are a subscriber.</p>
              ) : (
                <>
                  <p style={{color: 'red'}}>You are not a subscriber:</p>
                  <button onClick={handlePayment}>Assinatura</button>
                </>
              )}
            </div>
          ) : (
            <>
              <h1>Sign in to PhyStore</h1>
              <div className="ContainerInput">
                <input
                  type="email"
                  placeholder="UserName..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password..."
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button type="submit">Sign in</button>
                {loginError && <p style={{ color: 'red' }}>{loginError}</p>}
              </div>
            </>
          )}
        </div>
      </form>
    </div>
  );
}

export default CheckOut;
