import { Link } from 'react-router-dom';

//Function to cancel payment
const Success = () => {
  return (
    <div>
      <h1>Canceled</h1>
      <h2>Your purchase has been cancelled!</h2>
      <Link to="http://localhost:3000/">
        <button>Return to Checkout</button>
      </Link>
    </div>
  );
};

export default Success;
