import { Link } from 'react-router-dom';

//Function to success payment
const Success = () => {
  return (
    <div>
      <h1>Success</h1>
      <h2>Thank you for your purchase!</h2>
      <Link to="http://localhost:3000/">
        <button>Return to Checkout</button>
      </Link>
    </div>
  );
};

export default Success;
